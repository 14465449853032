<template>
    <div>
      <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
        <v-layout wrap justify-center>
            <v-flex xs12>
                <v-form ref="form" >
                    <v-layout wrap>
                        <v-flex xs12 align-self-center px-3>
                            <v-layout wrap>

                              <!-- <v-flex xs12  align-self-center text-left  px-4>
                                <span class="itemHeading">Upload EPFO Form</span>
                            </v-flex>
                              
          <v-layout wrap pt-4 px-4>
            <v-flex xs12 sm6 md4 pt-3 PX-4  >
              <span class="kumbhSemibold">EPFO File</span>
              <span class="red--text">*</span>
            </v-flex>
  
            <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.EPFOFile">
              <v-card outlined class="pa-3">
              <v-layout wrap align-center>
                <v-flex xs12 px-2>
                  <a target="_blank" :href="mediaURL + mydocs.documentDetails.EPFOFile">
                    <v-btn
                      style="border-radius: 14px; border: 0.5px solid #707070"
                      px-5
                      pt-0
                      text
                      small
                      outlined
                    >
                      View Bank File
                    </v-btn>
                  </a>
  &nbsp;
                  <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.EPFOFile.click()"
                  style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
                >
                  UPLOAD NEW
                </v-btn>
                <input
                  v-show="false"
                  id="file"
                  ref="EPFOFile"
                  type="file"
                  accept="application/pdf"
                  @change="bankDetailsUpload($event)"
                />
                </v-flex>
              
                 
              
              </v-layout>
            </v-card>
              <v-flex xs12>
                <v-chip
                  v-if="EPFOFile.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span>{{ EPFOFile.name.slice(0, 40) }}
                        <span v-if="EPFOFile.name.length > 40">
                          ...
                        </span></span
                      >
                    </v-flex>
                  </v-layout>
                </v-chip>
              </v-flex>
            </v-flex>
  
            <v-flex xs12 sm6 md8 text-left v-else>
              <v-card outlined class="pa-3">
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="EPFOFile.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ EPFOFile.name.slice(0, 40) }}
                          <span v-if="EPFOFile.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.EPFOFile.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD EPFO File
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="EPFOFile"
                    type="file"
                    accept="application/pdf"
                    @change="EPFOUpload($event)"
                  />
                </v-flex>
              </v-layout>
              </v-card>
            </v-flex>
            
          </v-layout>
         
          <v-flex xs12 py-5 >
            <v-divider class="my-4"></v-divider>
            <v-flex text-center > <span class="itemHeading">Or Fill Up EPFO Form</span></v-flex>
            
          </v-flex> -->
          <v-flex xs12 text-end>
            <v-btn
              style="font-family: kumbhBold"
              dark
              color="#005f32"
              href="https://wtiadmin.in/i/1739255132050.pdf"
              target="_blank"
            >
            <span style="color: white;">  Download EPFO Form </span> 
            </v-btn>
          </v-flex>
                                <v-flex xs12  align-self-center text-left  px-4>
                                    <span class="itemHeading">Personal Details</span>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.employeeDetails" >
                                  <span class="docValue">Name of the member</span>
                                 
                                    <v-text-field disabled :hide-details="true" v-model="mydocs.employeeDetails.empname" class="pt-2" outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Father's Name / Spouse's Name</span>
                                  <span class="red--text">*</span>
                                    <v-text-field :hide-details="true" v-model="mydocs.documentDetails.fathersName" class="pt-2"  outlined dense></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left pt-2 px-4 v-if="mydocs && mydocs.employeeDetails">
                                  <span class="docValue">Date Of Birth</span>
                                  <span class="red--text">*</span>
                                    <v-menu
                                    ref="menu"
                                    v-model="menu" 
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y 
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                      v-model="mydocs.employeeDetails.dob"
                                     
                                      :hide-details="true"
                                      class="pt-2"
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="dob"
                                      @input="menu = false"
                                      :max="new Date().toISOString().substr(0, 10)" 
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pt-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Email</span>
                                  <span class="red--text">*</span>
                                    <v-text-field class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.emailId"  outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left  pt-2 px-4 v-if="mydocs && mydocs.employeeDetails">
                                  <span class="docValue">Phone Number</span>
                                  <span class="red--text">*</span>
                                    <v-text-field :hide-details="true" v-model="mydocs.employeeDetails.mobileNo" class="pt-2"  outlined type="number" dense></v-text-field>
                                </v-flex>
                                <v-flex v-if="mydocs && mydocs.employeeDetails"
                                xs12
                                sm6
                                align-self-center
                                text-left
                                pa-2
                                px-4
                              >
                              <span class="docValue">Gender</span>
                              <span class="red--text">*</span>
                              <v-select 
                               v-model="mydocs.employeeDetails.gender"
                              :items="['Male', 'Female', 'Transgender']"
                             
                              class="pt-2"  :hide-details="true"
                              outlined
                              dense
                            ></v-select>
                              </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Marital Status</span>
                                  <span class="red--text">*</span>
                                    <v-select
                                v-model="mydocs.documentDetails.maritalStatus" 
                                    :items="['Married', 'Unmarried','Widow','Widower','Divorcee']"
                              
                                    outlined class="pt-2"
                                    dense
                                  ></v-select>
                                </v-flex>
                                <v-flex xs12  align-self-center text-left pa-2 px-4>
                                    <span class="itemHeading">Present Employment Details</span>
                                </v-flex>

                                <v-flex xs12  align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.employeeDetails">
                                  <span class="docValue">Date of joining</span>
                               
                                    <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field disabled
                                        v-model="mydocs.employeeDetails.dateofjoining"
                                      class="pt-2"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="mydocs.employeeDetails.dateofjoining"
                                      @input="menu2 = false"
                                      :max="new Date().toISOString().substr(0, 10)" 
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>

                                <v-flex xs12  align-self-center text-left pa-2 px-4>
                                    <span class="itemHeading">KYC Details</span>
                                </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Bank Account Number</span>
                                  <span class="red--text">*</span>
                                    <v-text-field class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.bankAcNum"  outlined dense></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">IFS Code of the branch</span>
                                  <span class="red--text">*</span>
                                    <v-text-field  class="pt-2" :hide-details="true"  v-model="mydocs.documentDetails.IfcCode"  outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Aadhar Number</span>
                                  <span class="red--text">*</span>
                                    <v-text-field  class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.adharNum"  outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Permanent Account Number (PAN), if available</span>
                                  <span class="red--text">*</span>
                                    <v-text-field  class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.panNum"   outlined dense></v-text-field>
                                </v-flex>
                               
                                <v-flex xs12 align-self-center text-left  pa-2 pt-5 px-7 v-if="mydocs && mydocs.documentDetails">
                                  <v-layout row align-center pt-5>
                                    <span class="itemText2 mr-1">
                                      Whether earlier a member of Employees' Provident Fund Scheme, 
                                      1952 
                                    </span>
                                    <v-checkbox v-model="mydocs.documentDetails.EPFS1952"
                                      color="primary"
                                      hide-details
                                      class="mt-0"
                                    ></v-checkbox>
                                  </v-layout>
                              </v-flex>

                              <v-flex xs12 align-self-center text-left pt-10 pa-2 px-7 v-if="mydocs && mydocs.documentDetails">
                                  <v-layout row align-center>
                                    <span class="itemText2 mr-16">
                                      Whether earlier a member of Employees' Pension Scheme, 1995
                                    </span>
                                    <v-checkbox v-model="mydocs.documentDetails.EPS1995"
                                      color="primary"
                                      hide-details
                                      class="mt-0"
                                    ></v-checkbox>
                                  </v-layout>
                              </v-flex>
                            
                              <template v-if="mydocs && mydocs.documentDetails && (mydocs.documentDetails.EPFS1952 || mydocs.documentDetails.EPS1995)">


                                  <v-flex xs12  align-self-center text-left pa-2 px-4 pt-8 >
                                    <span class="itemHeading">Previous Employment Details - Un-exempted </span>
                                </v-flex>

                                  <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                    <span class="docValue">Establishment Name & Address</span>
                                    <span class="red--text">*</span>
                                    <v-text-field v-model="mydocs.documentDetails.CompName"  class="pt-2" :hide-details="true"  outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Universal Account Number</span>
                                  <span class="red--text">*</span>
                                    <v-text-field  class="pt-2" :hide-details="true"  v-model="mydocs.documentDetails.UniversalAcNum"  outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">PF Account Number </span>
                                  <span class="red--text">*</span>
                                    <v-text-field class="pt-2" :hide-details="true"  v-model="mydocs.documentDetails.PFAcNum"  outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Date of joining (DD/MM/YYYY) </span>
                                  <span class="red--text">*</span>
                                    <v-menu
                                    ref="menu3"
                                    v-model="menu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field v-model="mydocs.documentDetails.unexpdateofjoining"
                                      
                                        class="pt-2" :hide-details="true"
                                       
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="mydocs.documentDetails.unexpdateofjoining"
                                      @input="menu3 = false"
                                      :max="new Date().toISOString().substr(0, 10)" 
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Date of exit (DD/MM/YYYY)</span>
                                  <span class="red--text">*</span>
                                    <v-menu
                                    ref="menu4"
                                    v-model="menu4"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                     v-model="mydocs.documentDetails.dateofExist"
                                      class="pt-2" :hide-details="true"
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="dob"
                                      @input="menu4 = false"
                                      :max="new Date().toISOString().substr(0, 10)" 
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Scheme Certificate No. (if issued)</span>
                                  <span class="red--text">*</span>
                                    <v-text-field  class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.SchemeCertifNum"  outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pt-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">PPONumber (if issued) </span>
                                  <span class="red--text">*</span>
                                    <v-text-field  class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.PPONum"  outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pt-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Non Contributory Period (NCP) Days</span>
                                  <span class="red--text">*</span>
                                    <v-text-field class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.NonContributoryPeriod"   outlined dense></v-text-field>
                                </v-flex>



                                
                                <v-flex xs12  align-self-center text-left pa-2 px-4 pt-8>
                                  <span class="itemHeading">Previous Employment Details - For Exempted Trusts </span>
                              </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                  <span class="docValue">Name & Address of the Trust</span>
                                  <span class="red--text">*</span>
                                  <v-text-field  class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.trustName"  outlined dense></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left  px-4 v-if="mydocs && mydocs.documentDetails">
                                <span class="docValue">Universal Account Number (UAN)</span>
                                <span class="red--text">*</span>
                                  <v-text-field  class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.UAN"    outlined dense></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pt-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                <span class="docValue">Member EPS A/C Number</span>
                                <span class="red--text">*</span>
                                  <v-text-field class="pt-2" :hide-details="true"   v-model="mydocs.documentDetails.memberEPSnum" outlined dense></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left  pt-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                <span class="docValue">Date of joining (DD/MM/YYYY)</span>
                                <span class="red--text">*</span>
                                  <v-menu
                                  ref="menu5"
                                  v-model="menu5"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                   v-model="mydocs.documentDetails.epsdateofjoining"
                                    
                                      class="pt-2" :hide-details="true"
                                      
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="mydocs.documentDetails.epsdateofjoining"
                                    @input="menu5 = false"
                                    :max="new Date().toISOString().substr(0, 10)" 
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                <span class="docValue">Date of exit (DD/MM/YYYY)</span>
                                <span class="red--text">*</span>
                                  <v-menu
                                  ref="menu6"
                                  v-model="menu6"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="mydocs.documentDetails.epsdateofExist"
                                     
                                      class="pt-2" :hide-details="true"
                                      readonly
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="mydocs.documentDetails.epsdateofExist"
                                    @input="menu6 = false"
                                    :max="new Date().toISOString().substr(0, 10)" 
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                <span class="docValue">Scheme Certificate No. (if issued)</span>
                                <span class="red--text">*</span>
                                  <v-text-field  class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.SchemeCertifNum"   outlined dense></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                <span class="docValue">Non Contributory Period (NCP) Days</span>
                                <span class="red--text">*</span>
                                  <v-text-field   class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.epsNonContributoryPeriod"  outlined dense></v-text-field>
                              </v-flex>
                            </template>


                              <v-flex xs12   align-self-center text-left pt-8 pa-2 px-7 v-if="mydocs && mydocs.documentDetails">
                                <v-layout row align-center>
                                  <span class="itemHeading mr-16">
                                    International Worker
                                  </span>
                                  <v-checkbox  v-model="mydocs.documentDetails.inertnationalWorker"
                                    color="primary"
                                    hide-details
                                    class="mt-0"
                                  ></v-checkbox>
                                </v-layout>
                              </v-flex>
                              <template v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.inertnationalWorker">

                              <v-flex xs12  pt-4 sm6 align-self-center text-left px-4 v-if="mydocs && mydocs.documentDetails">
                                <span class="docValue">State country of origin (India/Name of other country) </span>
                                <span class="red--text">*</span>
                                <v-text-field class="pt-2" :hide-details="true" v-model="mydocs.documentDetails.countryOfOrigin"  outlined dense></v-text-field>
                            </v-flex>
                            <v-flex xs12 pt-4 sm6 align-self-center text-left px-4 v-if="mydocs && mydocs.documentDetails">
                              <span class="docValue">Passport No</span>
                                <span class="red--text">*</span>
                                <v-text-field class="pt-2" :hide-details="true"  v-model="mydocs.documentDetails.passportNum"  outlined dense></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 align-self-center text-left px-4 v-if="mydocs && mydocs.documentDetails">
                              <span class="docValue">Validity of passport- Start Date</span>
                              <span class="red--text">*</span>
                              <v-menu
                              ref="menu7"
                              v-model="menu7"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="mydocs.documentDetails.validityOfPassportStart"
                                  class="pt-2" :hide-details="true"
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="mydocs.documentDetails.validityOfPassportStart"
                                @input="menu7 = false"
                                :max="new Date().toISOString().substr(0, 10)" 
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>

                          <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                            <span class="docValue">Validity of passport- End Date</span>
                              <span class="red--text">*</span>
                            <v-menu
                            ref="menu8"
                            v-model="menu8"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                             v-model="mydocs.documentDetails.validityOfPassportEnd"
                               class="pt-2" :hide-details="true"
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="mydocs.documentDetails.validityOfPassportEnd"
                              @input="menu8 = false"
                              :max="new Date().toISOString().substr(0, 10)" 
                            ></v-date-picker>
                          </v-menu>
                        </v-flex>
</template>
                        <v-flex xs12 sm12 align-self-center text-left pt-6 pa-2 px-4>
                          <span class="kumbhSemibold">Signature Of the employee</span>
                          <span class="red--text">*</span>
                    </v-flex>

                    <v-flex xs12 sm12 align-self-center text-left pa-2 px->
                        <v-card outlined class="pa-3">
                            <v-layout wrap row align-center justify-end pa-4>
                                <v-img
                                    v-if="croppedImage"
                                    :src="croppedImage"
                                    max-width="100"
                                    max-height="100"
                                    class="mb-3"
                                ></v-img>

                                <v-img
                                    v-else-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.signOfMember"
                                    :src="mediaURL + mydocs.documentDetails.signOfMember"
                                    max-width="100"
                                    max-height="100"
                                    class="mb-3"
                                ></v-img>

                                <v-btn
                                    color="#005f32"
                                    dark
                                    small
                                    @click="$refs.signOfMember.click()"
                                    class="ml-3"
                                >
                                    <span style="font-size: 12px;">{{ mydocs && mydocs.documentDetails && mydocs.documentDetails.signOfMember ? 'UPLOAD NEW Signature' : 'UPLOAD Signature' }}</span>
                                </v-btn>

                                <input
                                    v-show="false"
                                    ref="signOfMember"
                                    type="file"
                                    accept="image/*"
                                    @change="onImageSelected"
                                />

                                <v-dialog v-model="dialog" max-width="500">
                                    <v-card>
                                        <v-card-title class="headline">Crop your image</v-card-title>
                                        <v-card-text>
                                            <Cropper
                                                ref="cropper"
                                                :src="imagePreview"
                                                :aspect-ratio="1"
                                                :view-mode="1"
                                                style="width: 100%; height: 300px;"
                                            />
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" text @click="cropImage">Crop</v-btn>
                                            <v-btn color="secondary" text @click="closeDialog">Cancel</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-layout>
                        </v-card>
                    </v-flex>

                                <!-- <v-flex xs12 sm6 pa-2 px-4 v-if="flagg">
                                  <ImageComp
                                    @stepper="winStepper"
                                    :height="'320'"
                                    :width="'320'"
                                    :heading="'Upload Profile Picture'"
                                    :componentType="'coverImage'"
                                  />
                                </v-flex> -->
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <v-layout wrap justify-end py-5 px-5>
                      <v-flex xs12 text-end>
                        <v-btn color="#005f32" dark @click="add()"
                          ><span>Save Details</span></v-btn
                        >
                      </v-flex>
                    </v-layout>
                </v-form>
            </v-flex>

        </v-layout>
    </div>
</template>


<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import axios from "axios";

export default {
  components: {
        Cropper,
    },
    data() {
        return {
            imagePreview: null,
            croppedImage: null,
            dialog: false,
            showSnackBar: false,
            timeout: 5000,
            msg: null,
            appLoading: false,
            EPFOFile:"",
            mydocs: {},
            ServerError: false,
            menu: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false,
            menu7: false,
            menu8: false,

            empname:"",
fathersName:"",
dob:"",
gender:"",
mobileNo:"",
dateofjoining:"",
emailId:"",
maritalStatus:"",
bankAcNum:"",
IfcCode:"",
adharNum:"",
dateofExist:"",
countryOfOrigin:"",
validityOfPassportEnd:"",
validityOfPassportStart:"",
panNum:"",
EPS1995:"",
CompName :"",
PFAcNum:"",
unexpdateofjoining:"",
EPFS1952:"",
UniversalAcNum:"",

            // emergencyContactNo: "",
            // permanentAddress: "",
            // temporaryAddress: "",
         
            formData: new FormData(),
        };
    },
    mounted() {
        this.getMyUploads();
    },
    methods: {
      EPFOUpload(event) {
        this.EPFOFile = event.target.files[0];
      },
     
        getMyUploads() {
            this.appLoading = true;
            axios({
                url: "/employee/EPFOFormView",
                method: "GET",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
                params: {
                    id: localStorage.getItem("userid"),
                },
            })
            .then((response) => {
                this.appLoading = false;
                if (response.data.status) {
                  
                    this.mydocs = response.data.data;
                    // Assign backend data to nomineeDetails including _id
                    
                } else {
                    this.showSnackBar = true;
                    this.msg = response.data.msg;
                }
            })
            .catch((err) => {
                this.appLoading = false;
                this.ServerError = true;
                console.error(err);
            });
        },

        onImageSelected(event) {
            const file = event.target.files[0];
            if (file) {
                this.signOfMember = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                    this.dialog = true;
                };
                reader.readAsDataURL(file);
            }
        },
        cropImage() {
            const result = this.$refs.cropper.getResult();
            if (result) {
                result.canvas.toBlob((blob) => {
                    this.croppedImage = URL.createObjectURL(blob);
                    this.signOfMember = blob;
                    this.dialog = false;
                });
            }
        },
        closeDialog() {
            this.dialog = false;
        },
        add() {
    // Function to display an error and stop validation
    const showError = (message) => {
        this.msg = message; // Display the error message
        this.showSnackBar = true; // Show snackbar or alert
        throw new Error(message); // Stop further execution
    };

    try {
        // Validate Employee Details
        if (!this.mydocs.employeeDetails.empname) {
            showError("Employee name is required.");
        }
        if (!this.mydocs.documentDetails.fathersName) {
            showError("Father's name is required.");
        }
        if (!this.mydocs.employeeDetails.mobileNo || !/^\d{10}$/.test(this.mydocs.employeeDetails.mobileNo)) {
            showError("Valid mobile number is required.");
        }
        if (!this.mydocs.documentDetails.emailId || !/^\S+@\S+\.\S+$/.test(this.mydocs.documentDetails.emailId)) {
            showError("Valid email address is required.");
        }
        if (!this.mydocs.employeeDetails.dateofjoining) {
            showError("Date of joining is required.");
        }

        // Validate Document Details
        if (!this.mydocs.documentDetails.adharNum || this.mydocs.documentDetails.adharNum.length !== 12) {
            showError("A valid Aadhaar number is required (12 digits).");
        }
        if (!this.mydocs.documentDetails.panNum || this.mydocs.documentDetails.panNum.length !== 10) {
            showError("A valid PAN number is required (10 characters).");
        }

        // Proceed if validation passes
        let formData = new FormData();
        formData.append("id", localStorage.getItem("userid"));
        formData.append("signOfMember", this.signOfMember);

        // Employee Details
        formData.append("empname", this.mydocs.employeeDetails.empname);
        formData.append("fathersName", this.mydocs.documentDetails.fathersName);
        formData.append("dob", this.mydocs.employeeDetails.dob);
        formData.append("gender", this.mydocs.employeeDetails.gender);
        formData.append("mobileNo", this.mydocs.employeeDetails.mobileNo);
        formData.append("dateofjoining", this.mydocs.employeeDetails.dateofjoining);

        // Document Details
        formData.append("emailId", this.mydocs.documentDetails.emailId);
        formData.append("maritalStatus", this.mydocs.documentDetails.maritalStatus);
        formData.append("bankAcNum", this.mydocs.documentDetails.bankAcNum);
        formData.append("IfcCode", this.mydocs.documentDetails.IfcCode);
        formData.append("adharNum", this.mydocs.documentDetails.adharNum);
        formData.append("panNum", this.mydocs.documentDetails.panNum);
        formData.append("EPFS1952", this.mydocs.documentDetails.EPFS1952);
        formData.append("EPS1995", this.mydocs.documentDetails.EPS1995);

        //formData.append("EPFOFile", this.mydocs.documentDetails.EPFOFile);

        // Axios call
        axios({
            method: "POST",
            url: "/employee/epfo/upload",
            data: formData,
            headers: {
                "x-auth-token": localStorage.getItem("token"),
                "Content-Type": "multipart/form-data"
            },
        })
        .then((response) => {
            if (response.data.status === true) {
                this.msg = response.data.msg;
                this.showSnackBar = true;
                formData = new FormData();
                this.appLoading = false;
                this.getMyUploads();
            } else {
                this.appLoading = false;
                this.msg = response.data.msg;
                this.showSnackBar = true;
                formData = new FormData();
            }
        })
        .catch((err) => {
            this.ServerError = true;
            console.error(err);
        });
    } catch (e) {
        // Catch the error thrown by showError to stop further execution
        console.error("Validation failed:", e.message);
    }
},


    },
};
</script>